

/* Full-page container */
#Page-Not-Found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

/* Glassmorphic content container */
.container {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Heading styling */
h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #e0e0e0;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #cfcfcf;
}

/* Button styling */
#Btn-Back-Home {
  padding: 15px 25px;
  background: linear-gradient(45deg, #6a82fb, #fc5c7d);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

#Btn-Back-Home:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(252, 92, 125, 0.5);
}

/* ======================= */
/* Base Card Styles */
/* ======================= */
.digital-card {
  font-family: Arial, sans-serif;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0,0,0,0.25);
  margin: 20px auto;
  max-width: 500px;
}

/* ======================= */
/* Section 1: Header */
/* ======================= */
.card-section-1 {
  background: var(--section1-bg);
  color: #fff;
  padding: 5px 15px 15px 15px;
}

.section1-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Profile row: profile image and business name in a row */
.profile-row {
  display: flex;
  align-items: center;
  padding-left: 25px;
  gap: 15px;
}
.profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(255,255,255,0.3);
}
.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business-name {
  font-size: 1.5rem;
  text-shadow: 0 2px 5px rgba(0,0,0,0.5);
  margin: 0;
}

/* QR code and actions arranged vertically */
.qr-and-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.qr-code {
  text-align: center;
}
.actions-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.actions-col .btn {
  background: white;
  color:#1187ac;
  text-emphasis: none;
  border: none;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.actions-col .btn:hover {
  transform: translateY(-2px);
}

/* Color changer */
.color-changer {
  text-align: center;
  font-size: 0.9rem;
}
.color-changer label {
  margin-right: 8px;
}
.color-changer input[type="color"] {
  border: none;
  background: none;
  cursor: pointer;
}

/* ======================= */
/* Section 2: Details */
/* ======================= */
.card-section-2 {
  background: #fff;
  color: #333;
  padding: 15px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.detail-item .icon {
  font-size: 1.2rem;
  margin-right: 10px;
  color: #1187ac;
}
.detail-item a {
  color: #1187ac;
  text-decoration: none;
  transition: color 0.3s ease;
}
.detail-item a:hover {
  color: #0a5476;
}

/* Dividers between details */
.details hr {
  border: none;
  border-top: 1px solid #eee;
  margin: 5px 0;
}

/* About Us section */
.about-us {
  padding: 10px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-top: 10px;
}
.about-us h4 {
  margin: 0 0 5px;
  font-size: 1rem;
  color: #1187ac;
}
.about-us p {
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* ======================= */
/* Responsive Layout: Desktop */
/* ======================= */
@media (min-width: 769px) {
  .digital-card {
    display: flex;
    max-width: 800px;
  }
  /* Section 1 becomes left column, Section 2 right column */
  .card-section-1, .card-section-2 {
    flex: 1;
    padding: 20px;
  }
  .card-section-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section1-header{
    gap:10px;
  }
}

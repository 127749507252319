/* Page Background & Centering */
.payment-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #74ebd5, #acb6e5);
  padding: 20px;
}

/* Card Container */
.card {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Header */
.card-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #1e90ff;
  color: #fff;
}

.back-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

.card-header h1 {
  margin: 0;
  font-size: 1.8rem;
}

/* Body */
.card-body {
  padding: 20px;
}

.description {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #333;
}

.price-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.price-label {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 10px;
}

.price {
  font-size: 1.3rem;
  color: #27ae60;
}

.features-section {
  margin-bottom: 20px;
}

.features-section h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: #333;
}

.features {
  list-style-type: disc;
  padding-left: 20px;
  color: #555;
  font-size: 1.1rem;
}

.terms-section {
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
}

.terms-section a {
  color: #1e90ff;
  text-decoration: none;
}

/* Agree Checkbox */
.agree-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.95rem;
  color: #333;
  margin-top: 10px;
}

.agree-checkbox input[type="checkbox"] {
  transform: scale(1.2);
  cursor: pointer;
}

/* Error Message */
.error {
  background: #f8d7da;
  color: #842029;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
}

/* Footer & Payment Button */
.card-footer {
  padding: 15px;
  text-align: center;
  background: #f2f2f2;
}

.pay-btn {
  background: #27ae60;
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.pay-btn:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

.pay-btn:hover:enabled {
  background: #219150;
}

/* Payment Success View */
.payment-success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #74ebd5, #acb6e5);
  padding: 20px;
}

.success-card {
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.success-card h1 {
  font-size: 2.5rem;
  color: #27ae60;
  margin-bottom: 20px;
}

.success-card p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
}

.premium-benefits {
  margin-bottom: 30px;
  text-align: left;
}

.premium-benefits h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

.premium-benefits ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #555;
  font-size: 1.1rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.explore-button,
.signin-button {
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.explore-button {
  background: #3498db;
  color: #fff;
}

.explore-button:hover {
  background: #2980b9;
}

.signin-button {
  background: #27ae60;
  color: #fff;
}

.signin-button:hover {
  background: #219150;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .card-header h1 {
    font-size: 1.5rem;
  }
  
  .description, .price-label, .price, .features, .features-section h2 {
    font-size: 1rem;
  }
  
  .card-body, .card-footer {
    padding: 15px;
  }
  
  .pay-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

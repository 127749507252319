.verified-affiliate-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.verified-affiliate-heading {
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.search-container {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 80%;
  max-width: 400px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.verified-affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.verified-affiliate-table th,
.verified-affiliate-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  color:#333;
}

.verified-affiliate-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.view-btn {
  background: #2563eb;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

/* Modal Styles */
.affiliate-profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.affiliate-profile-content {
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  position: relative;
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4f;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.edit-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.edit-form input,
.edit-form textarea {
  width: 100%;
  padding: 6px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions {
  margin-top: 20px;
  text-align: right;
}

.edit-btn,
.save-btn,
.cancel-btn {
  background: #2563eb;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn {
  background: #ff4d4f;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.users-table th,
.users-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  
}

.users-table th {
  background-color: #f2f2f2;
}

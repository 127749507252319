/* AdminDashboard.css */

/* Overall container for the dashboard */
.admin-dashboard-container {
    display: flex;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Sidebar styling */
  .sidebar {
    width: 250px;
    background: linear-gradient(to bottom, #1e3a8a, #1e40af);
    color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Sidebar header */
  .sidebar-header {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Navigation list styling */
  .sidebar-nav {
    display: flex;
    flex-direction: column;
  }
  
  /* Navigation item styling */
  .sidebar-nav-item {
    background: transparent;
    border: none;
    color: #ffffff;
    text-align: left;
    padding: 12px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: background 0.3s ease;
  }
  
  /* Icon styling inside nav items */
  .sidebar-nav-item .icon {
    margin-right: 10px;
  }
  
  /* Hover effect for navigation items */
  .sidebar-nav-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Active navigation item styling */
  .sidebar-nav-item.active {
    background-color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
  }
  
  /* Main content area styling */
  .admin-dashboard-content {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: auto;
  }
  
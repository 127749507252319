

:root {
  --Color1: #a1c4fd; /* You can adjust this accent color as needed */
}

.Progress-Indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

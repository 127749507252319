.affiliate-payment-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.affiliate-payment-container h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.affiliates-payment-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.affiliates-payment-table th,
.affiliates-payment-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
}

.affiliates-payment-table th {
  background: #f0f0f0;
  font-weight: bold;
}

/* Filter Section within Modal */
.filter-section {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.filter-section label {
  font-size: 1rem;
  color: #333;
}

.filter-section input {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-btn {
  background: #2563eb;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

/* Payment Modal Popup */
.payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
}

.payment-modal-content {
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.payment-modal-content h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.payment-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0;
}

.payment-inputs label {
  font-size: 1rem;
  color: #333;
}

.payment-inputs input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.payment-modal-content button {
  background: #27ae60;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
  margin: 10px;
}

.payment-modal-content button:hover {
  background: #219150;
}

.modal-close {
  background: #e74c3c;
}

.modal-close:hover {
  background: #c0392b;
}

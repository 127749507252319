/* UserInfo.css */
.user-info-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.user-info-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

/* Top control area for category selection and search */
.user-info-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.category-selector {
  margin-bottom: 15px;
}

.category-selector button {
  background: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.category-selector button.active,
.category-selector button:hover {
  background: #3498db;
  color: #fff;
  border-color: #3498db;
}

.search-box input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border 0.3s;
}

.search-box input:focus {
  border-color: #3498db;
}

/* User List Section */
.user-list h2 {
  font-size: 2rem;
  color: #444;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.user-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.user-details {
  flex: 1;
}

.user-details p {
  margin: 5px 0;
  color: #555;
}

.block-button,
.unblock-button {
  background: #e74c3c;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.unblock-button {
  background: #27ae60;
}

.block-button:hover {
  background: #c0392b;
}

.unblock-button:hover {
  background: #1e8c4a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-box input {
    width: 100%;
  }
  .category-selector button {
    padding: 8px 12px;
    margin: 0 3px;
  }
}

/* AdControl.css */

.admin-container {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    font-family: 'Inter', sans-serif;
    background: linear-gradient(135deg, #f6f9fc, #e3edf7);
    border-radius: 12px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
    transition: 0.3s ease-in-out;
  }
  
  .admin-container:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  /* Back Button (if needed) */
  .back-button {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.3s;
  }
  
  .back-button:hover {
    background: #217dbb;
    transform: scale(1.05);
  }
  
  /* Admin Card */
  .admin-card {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
  }
  
  .admin-card:hover {
    transform: translateY(-3px);
  }
  
  .admin-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form Styling */
  .admin-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 1rem;
    font-weight: 600;
    color: #444;
    display: block;
    margin-bottom: 8px;
  }
  
  /* Inputs */
  .input-field {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    transition: 0.3s;
  }
  
  .input-field:focus {
    border-color: #3498db;
    box-shadow: 0px 0px 8px rgba(52, 152, 219, 0.3);
  }
  
  /* File Input */
  .file-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
  }
  
  .file-input:hover {
    background: #eef2f7;
  }
  
  /* Save Button */
  .save-button {
    background: linear-gradient(135deg, #27ae60, #20a24f);
    color: white;
    padding: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
  }
  
  .save-button:hover {
    background: linear-gradient(135deg, #219150, #1c8a46);
    transform: scale(1.05);
  }
  
  /* Preview Containers */
  
  /* Desktop Preview: Simulates a banner with 100% width and 100px height */
  .desktop-preview {
    width: 100%;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    position: relative;
  }
  
  .desktop-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Mobile Preview: Simulates a banner with 100% width and 50px height */
  .mobile-preview {
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    position: relative;
  }
  
  .mobile-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Delete Button for Image Previews */
  .delete-button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    position: absolute;
    top: 8px;
    right: 8px;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #ff1a1a;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .admin-container {
      padding: 20px;
    }
    .admin-title {
      font-size: 1.7rem;
    }
    .input-field,
    .file-input {
      padding: 10px;
    }
    .save-button {
      padding: 12px;
    }
  }
  
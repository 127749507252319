.affiliate-container {
  background: #ffffff;
  max-width: 500px;
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  margin: 20px auto;
}

.affiliate-heading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.affiliate-subheading {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.affiliate-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
}

.bank-heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color:black;
}

.terms-group {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.terms-group input[type="checkbox"] {
  margin-right: 6px;
  width: auto;
}

.affiliate-form a {
  color: #2563eb;
  text-decoration: underline;
}

.submit-btn {
  width: 100%;
  background: #2563eb;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 12px;
}

.referral-link {
  margin-top: 20px;
  text-align: center;
}

.referral-link p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.referral-link a {
  color: #2563eb;
  text-decoration: underline;
  font-size: 14px;
}

.success-heading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}
/* Responsive Styles */
@media (max-width: 600px) {
  .affiliate-container {
    margin: 10px;
    padding: 16px;
  }
  
  .affiliate-heading {
    font-size: 20px;
  }
  
  .submit-btn {
    font-size: 14px;
    padding: 8px;
  }
}

.error-message {
  background: #ffcccc;
  color: #d8000c;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}




#Edit-Profile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Title styling */
.MT0 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #e0e0e0;
}

/* Field styling */
.Field {
  margin-bottom: 20px;
  position: relative;
}

.Field input {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
  font-size: 600;
}

.Field input::placeholder {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

.Field input:focus {
  border-color: #a1c4fd;
  box-shadow: 0 0 10px rgba(161, 196, 253, 0.5);
}

/* Error message styling */
.Error {
  font-size: 0.9rem;
  color: #ff6b6b;
  margin-top: 5px;
  display: block;
}

/* Submit button styling */
.Btn-Submit {
  width: 100%;
  padding: 15px;
  background: linear-gradient(45deg, #6a82fb, #fc5c7d);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.Btn-Submit:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(252, 92, 125, 0.5);
}

/* Login link styling */
.Login-Link {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
}

.Login-Link a {
  color: #a1c4fd;
  text-decoration: none;
  font-weight: 500;
}

.Login-Link a:hover {
  text-decoration: underline;
}

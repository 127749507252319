

/* Full-page background */
.signin-background {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background: linear-gradient(135deg, #0f2027, #203a43, #2c5364); */
  padding: 20px;
}

/* Glassmorphic container with a subtle color shift */
.signin-container {
  background: rgba(255, 255, 255, 0.15); /* Slightly more opaque */
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 40px;
  max-width: 400px;
  width: 100%;
  backdrop-filter: blur(15px); /* Increased blur effect */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5); /* Slightly stronger shadow */
}

/* Adjust title color for better contrast */
.signin-title {
  color: #ffffff; /* Pure white for sharper contrast */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Title styling */
.signin-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #e0e0e0;
}

/* Form layout */
.signin-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input group styling */
.input-group {
  position: relative;
}

.input-group input {
  width: 100%;
  padding: 15px 50px 15px 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
  font-size: 600;
}

.input-group input::placeholder {
  color: rgba(255, 255, 255, 1);
  font-size: 600;
}

.input-group input:focus {
  border-color: #a1c4fd;
  box-shadow: 0 0 10px rgba(161, 196, 253, 0.5);
}

/* Toggle password icon */
.toggle-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 1.2rem;
}

/* Error message styling */
.error-message {
  font-size: 0.9rem;
  color: #ff6b6b;
  margin-top: 5px;
  text-align: left;
}

/* Forgot password link */
.forgot-password {
  display: block;
  text-align: right;
  color: #a1c4fd;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: -10px;
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Button styling */
.signin-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(45deg, #6a82fb, #fc5c7d);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signin-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(252, 92, 125, 0.5);
}

/* Signup link styling */
.signup-link {
  text-align: center;
  color: #e0e0e0;
  font-size: 0.9rem;
}

.signup-link a {
  color: #a1c4fd;
  text-decoration: none;
  font-weight: 500;
}

.signup-link a:hover {
  text-decoration: underline;
}


/* Hide default browser password reveal/clear buttons */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear,
input[type="password"]::-webkit-textfield-decoration-container {
  display: none;
}

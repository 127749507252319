@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #000814, #001d3d);
  color: #fff;
}

/* Main Home container */
.home-container1 {
  position: relative;
 
  min-height: calc(100vh - 100px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease-out;
}

.free {
  margin-top: 100px; /* Extra margin for free users */
}

.paid {
  margin-top: 0;
}

#Home-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adds spacing */
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Banner Ad container with fixed height */
#Banner-Ad {
  position: fixed;
  top: 0;
  width: 100vw; /* Full viewport width */
  height: 100px; /* Fixed height */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers content vertically */
  overflow: hidden;
  z-index: 1000;
  background-color: #fff; /* Prevents background issues */
}

#Banner-Ad img {
  height: 100%; /* Fixed height */
  width: auto; /* Auto width to maintain aspect ratio */
  max-width: 100%; /* Prevents overflow */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Action Buttons container */
#Action-Buttons {
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
}

/* Top Right: Sign Out and Edit */
#Top-Right {
  display: flex;
  align-items: center;
  gap: 15px;
}

#Btn-Sign-Out {
  position: relative;
  z-index: 10; /* Ensures the button appears above other elements */
  padding: 10px 20px;
  background: #6a82fb;  /* Plain solid color */
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

#Btn-Sign-Out:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(252, 92, 125, 0.5);
}

#Edit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10%;
  color: #fff;
  width: 60px;
  height: 60px;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 999;
}

#Edit:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Bottom Right: WhatsApp and Upload Image */
#Bottom-Right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

#Whatsapp {
  text-decoration: none;
  transition: all 0.3s ease;
}

#Whatsapp:hover {
  transform: scale(1.1);
}

/* Media Query: Smaller buttons on small screens */
@media (max-width: 768px) {

  #Home {
    padding: 10px;
    padding-top: 0;
  }

  .home-container1 {
  gap: 5px;
  }

.free{
  margin-top: 50px;
}

  #Banner-Ad {
    height: 50px; /* Fixed height for the banner ad */
  }

  #Btn-Sign-Out {
    padding: 4px 8px;
    font-size: 0.5rem;
  }
  
  #Edit {
    width: 30px;
    height: 30px;
  }

  #Home-Content {
    gap: 0;
  }
  
  /* If you have an upload button, for example with id "Btn-Upload", scale it down as well */
  #Btn-Upload {
    padding: 4px 8px;
    font-size: 0.5rem;
  }
}

/* Admin Ad Banner Button */
#ad-button {
  background-color: #007bff; /* Primary blue color */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#ad-button:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
  transform: translateY(-2px);
}

#ad-button:active {
  background-color: #004494; /* Even darker for click effect */
  transform: translateY(0);
}

#ad-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

/* Login Button */
#login-button {
  background: #ff4757; /* Red color */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#login-button:hover {
  background: #e84118;
  transform: translateY(-2px);
}

#login-button:active {
  background: #c23616;
  transform: translateY(0);
}

#login-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(255, 71, 87, 0.5);
}

/* Responsive: Smaller button on mobile screens */
@media (max-width: 768px) {
  #login-button {
    padding: 10px 16px;
    font-size: 14px;
  }
}

/* Upgrade Popup Modal Styles */
.upgrade-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.upgrade-popup {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.upgrade-popup h2 {
  margin-bottom: 15px;
}

.upgrade-popup p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.popup-buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.upgrade-now-btn {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.upgrade-now-btn:hover {
  background: #218838;
}

.close-popup-btn {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.close-popup-btn:hover {
  background: #c82333;
}

/* Upgrade Button in Top Right */
#upgrade-button {
  background: #ffc107; /* Yellow-ish for attention */
  color: #333;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left: 10px;
}

#upgrade-button:hover {
  background: #e0a800;
  transform: translateY(-2px);
}

#upgrade-button:active {
  background: #c69500;
  transform: translateY(0);
}

#upgrade-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(255, 193, 7, 0.5);
}




.settings-nav-button {
  background: #1f55b8; /* Light gray background */
  border: none;
  padding: 10px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.settings-nav-button:hover {
  background: #164cb1; /* Darker shade on hover */
}

.settings-nav-button:active {
  transform: scale(0.9);
}

.settings-nav-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.5); /* Subtle blue glow */
}


.blocked-container {
  max-width: 90%;
  margin: 50px auto;
  padding: 20px;
  background: #ffe5e5; /* Light red background */
  border: 1px solid #ff4d4d; /* Red border */
  border-radius: 10px;
  text-align: center;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
}

.blocked-container h2 {
  color: #d80000; /* Dark red */
  font-size: 22px;
  margin-bottom: 10px;
}

.blocked-container p {
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}

.blocked-container strong {
  color: #d80000; /* Make the support email stand out */
  font-weight: bold;
}

.blocked-container a {
  color: #ff4d4d;
  font-weight: bold;
  text-decoration: none;
}

.blocked-container a:hover {
  text-decoration: underline;
}
